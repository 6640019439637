import { useParams } from "react-router-dom";
import races from "../data/races";
import { FaCalendarAlt, FaRunning } from "react-icons/fa";
import { BiRocket } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import ModalImage from "react-modal-image";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const SingleRace = () => {
  const { raceId } = useParams();
  const race = races.filter((r) => r.id === raceId)[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{race.title}</title>
        <meta name="description" content={race.description.par1.bih} />
      </Helmet>
      <div className={`bg-[#eee] ${!race.imageMap ? "pb-10" : ""}`}>
        <div className="relative w-full">
          <img
            src={race.imageUrlPage}
            className="w-full xs:h-32 md:h-40 lg:h-auto"
            alt="Hero"
          />
          <h1 className="text-white absolute xs:bottom-[2.2rem] sm:bottom-[2.5rem] xss:bottom-[2rem] xm:bottom-[2.5rem] xs:text-[25px] xm:text-4xl md:bottom-[2.5rem] lg:bottom-[3rem] xl:bottom-[4.5rem] 2xl:bottom-[7rem] 3xl:bottom-[9.5rem] md:text-5xl lg:text-6xl text-center w-full uppercase font-bold">
            {race.title}
          </h1>
        </div>
        <div className="grid xs:grid-cols-2 lg:grid-cols-4 items-center uppercase">
          <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
            <FaCalendarAlt className="text-green-600 text-3xl md:text-4xl" />
            <h3 className="text-center xs:mt-[1.55rem] md:mt-4 lg:mt-3 font-bold md:font-semibold text-[13px] md:text-base 2xl:text-lg h-16">
              {race.startDate.bih} <br /> {race.startDate.eng}
            </h3>
          </div>
          <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
            <BiRocket className="text-green-600 text-3xl md:text-4xl" />
            <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
              Start <br /> {race.race1.startTime.bih} /{" "}
              {race.race1.startTime.eng}
            </h3>
          </div>
          <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-1 md:space-x-8 justify-center">
            <MdLocationOn className="text-green-600 text-3xl md:text-4xl" />
            <h3 className="text-center xs:pt-5 font-semibold 2xl:text-lg h-16">
              {race.location}
            </h3>
          </div>
          <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
            <FaRunning className="text-green-600 text-3xl md:text-4xl" />
            <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
              Utrka / Race <br />{" "}
              {race.race1.raceLength
                ? race.race1.raceLength + "H"
                : race.race1.raceType}
            </h3>
          </div>
          <div className="bg-purple-700 h-full w-full py-3 px-6 mx-auto text-white uppercase xs:col-span-2 lg:col-span-4 divide-x-0 hover:opacity-90 duration-300 cursor-pointer shadow-xl  group">
            <a href={race.race1.prijavaLink} target="_blank" rel="noreferrer">
              <h2 className="text-center text-2xl xl:text-3xl font-semibold">
                {/* {race.id === "74a6c5c0-e049-ef0f-d8f1-a6dd0b702152" ? "Rezultati" : "Prijava / Register"} */}
                Prijava / Register
              </h2>
            </a>
          </div>
        </div>
        <div className="w-full lg:hidden bg-gray-300 h-1"></div>
        {race.race2 && (
          <>
            <div className="grid xs:grid-cols-2 lg:grid-cols-4 items-center uppercase mt-4">
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <FaCalendarAlt className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center xs:mt-6 md:mt-4 lg:mt-3 font-bold md:font-semibold text-[13px] md:text-base 2xl:text-lg h-16">
                  {race.startDate.bih} <br /> {race.startDate.eng}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <BiRocket className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
                  Start <br /> {race.race2.startTime.bih} /{" "}
                  {race.race2.startTime.eng}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-1 md:space-x-8 justify-center">
                <MdLocationOn className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center xs:pt-5 md:pt-4 font-semibold 2xl:text-lg h-16">
                  {race.location}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <FaRunning className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
                  Utrka / Race <br />{" "}
                  {race.race2.raceLength
                    ? race.race2.raceLength + "H"
                    : race.race2.raceType}
                </h3>
              </div>
              <div className="bg-purple-700 h-full w-full py-3 px-6 mx-auto text-white uppercase xs:col-span-2 lg:col-span-4 divide-x-0 hover:opacity-90 duration-300 cursor-pointer shadow-xl  group">
                <a
                  href={race.race2.prijavaLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="text-center text-2xl xl:text-3xl font-semibold">
                    {/* {race.id === "74a6c5c0-e049-ef0f-d8f1-a6dd0b702152" ? "Rezultati" : "Prijava / Register"} */}
                    Prijava / Register
                  </h2>
                </a>
              </div>
            </div>
            <div className="w-full lg:hidden bg-gray-300 h-1"></div>
          </>
        )}
        {race.race3 && (
          <>
            <div className="grid xs:grid-cols-2 lg:grid-cols-4 items-center uppercase mt-4">
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <FaCalendarAlt className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center xs:mt-6 md:mt-4 lg:mt-3 font-bold md:font-semibold text-[13px] md:text-base 2xl:text-lg h-16">
                  {race.startDate.bih} <br /> {race.startDate.eng}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <BiRocket className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
                  Start <br /> {race.race3.startTime.bih} /{" "}
                  {race.race3.startTime.eng}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-1 md:space-x-8 justify-center">
                <MdLocationOn className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center xs:pt-5 md:pt-4 font-semibold 2xl:text-lg h-16">
                  {race.location}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <FaRunning className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
                  Utrka / Race <br />{" "}
                  {race.race3.raceLength
                    ? race.race3.raceLength + "H"
                    : race.race3.raceType}
                </h3>
              </div>
              <div className="bg-purple-700 h-full w-full py-3 px-6 mx-auto text-white uppercase xs:col-span-2 lg:col-span-4 divide-x-0 hover:opacity-90 duration-300 cursor-pointer shadow-xl  group">
                <a
                  href={race.race3.prijavaLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="text-center text-2xl xl:text-3xl font-semibold">
                    {/* {race.id === "74a6c5c0-e049-ef0f-d8f1-a6dd0b702152" ? "Rezultati" : "Prijava / Register"} */}
                    Prijava / Register
                  </h2>
                </a>
              </div>
            </div>
          </>
        )}
        {race.race4 && (
          <>
            <div className="grid xs:grid-cols-2 lg:grid-cols-4 items-center uppercase mt-4">
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <FaCalendarAlt className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center xs:mt-6 md:mt-4 lg:mt-3 font-bold md:font-semibold text-[13px] md:text-base 2xl:text-lg h-16">
                  {race.startDate.bih} <br /> {race.startDate.eng}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 lg:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <BiRocket className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
                  {race.id === "324e5d10-3ddf-4907-8653-c126a9bbed57" &&
                    "Start"}
                  {/* Start <br /> {race.race4.startTime.bih} /{" "}
                  {race.race4.startTime.eng} */}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-1 md:space-x-8 justify-center">
                <MdLocationOn className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center xs:pt-5 md:pt-4 font-semibold 2xl:text-lg h-16">
                  {race.location}
                </h3>
              </div>
              <div className="bg-white xs:py-3 sm:py-1 xs:px-2 md:px-6 xs:border-b-2 xs:border-gray-200 sm:border-b-0 border-r-2 shadow-xl flex items-center h-full w-full space-x-3 md:space-x-8 justify-center">
                <FaRunning className="text-green-600 text-3xl md:text-4xl" />
                <h3 className="text-center mt-3 font-semibold 2xl:text-lg h-16">
                  Utrka / Race <br />{" "}
                  {race.race4.raceLength
                    ? race.race4.raceLength + "H"
                    : race.race4.raceType}
                </h3>
              </div>
              <div className="bg-purple-700 h-full w-full py-3 px-6 mx-auto text-white uppercase xs:col-span-2 lg:col-span-4 divide-x-0 hover:opacity-90 duration-300 cursor-pointer shadow-xl  group">
                <a
                  href={race.race4.prijavaLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h2 className="text-center text-2xl xl:text-3xl font-semibold">
                    {race.id === "324e5d10-3ddf-4907-8653-c126a9bbed57"
                      ? "Uskoro"
                      : "Prijava / Register"}
                  </h2>
                </a>
              </div>
            </div>
          </>
        )}
        <div className="bg-[#eee]">
          {race.id === "7c66f599-92a9-4f41-a731-f5f19ab7a4b8" && (
            <a
              className="flex space-x-2"
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLScCImSwxnL-JXNzNZX6-0OxzZQ0tMPW4JgnglonMbME00ZDvw/viewform"
            >
              <p className="xs:pl-6 md:pl-14 lg:pl-28 pt-8 text-2xl font-semibold text-purple-800 hover:text-purple-600 duration-300">
                PRIJAVA NA DJEČIJU UTRKU
              </p>
              <p className="bg-purple-800 mt-8 w-2 h-2 rounded-full animate-pulse"></p>
            </a>
          )}
          {race.description.par1 && (
            <>
              <p className="xs:px-6 md:px-14 lg:px-28 pt-8">
                {race.description.par1.bih}
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400">
                {race.description.par1.eng}
              </p>
            </>
          )}
          {race.description.par2 && (
            <>
              <p className="xs:px-6 md:px-14 lg:px-28 pt-5">
                {race.description.par2.bih}
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400">
                {race.description.par2.eng}
              </p>
            </>
          )}
          {race.description.par3 && (
            <>
              <p className="xs:px-6 md:px-14 lg:px-28 pt-5 whitespace-pre-wrap">
                {race.description.par3.bih}
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400 whitespace-pre-wrap">
                {race.description.par3.eng}
              </p>
            </>
          )}
          {race.description.par4 && (
            <>
              <p className="xs:px-6 md:px-14 lg:px-28 pt-5 whitespace-pre-wrap">
                {race.description.par4.bih}
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400 whitespace-pre-wrap">
                {race.description.par4.eng}
              </p>
            </>
          )}
          {race.description.par5 && (
            <>
              <p className="xs:px-6 md:px-14 lg:px-28 pt-5 whitespace-pre-wrap">
                {race.description.par5.bih}
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400 whitespace-pre-wrap">
                {race.description.par5.eng}
              </p>
            </>
          )}
          {race.description.par6 && (
            <>
              <p className="xs:px-6 md:px-14 lg:px-28 pt-5">
                {race.description.par6.bih}
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400">
                {race.description.par6.eng}
              </p>
            </>
          )}
          {race.description.par7 && (
            <>
              <p className="xs:px-6 md:px-14 lg:px-28 pt-5">
                {race.description.par7.bih}
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400">
                {race.description.par7.eng}
              </p>
            </>
          )}
          {race.description.heading && (
            <>
              <div className="mt-10 xs:px-6 md:px-14 lg:px-28 flex flex-row items-start space-x-5 md:space-x-6">
                <img
                  src={"/assets/quote.png"}
                  className="xs:w-6 md:w-8"
                  alt="Quote"
                />
                <p className="text-lg md:text-xl">
                  {race.description.heading.bih}
                </p>
              </div>
              {race.id !== "6dfe2c7a-9111-4453-b3ac-a9dfe58f60d4" && (
                <div className="mt-6 xs:px-6 md:px-14 lg:px-28 flex flex-row items-start space-x-5 md:space-x-6">
                  <img
                    src={"/assets/quote.png"}
                    className="xs:w-6 md:w-8"
                    alt="Quote"
                  />
                  <p className="text-lg md:text-xl text-blue-400">
                    {race.description.heading.eng}
                  </p>
                </div>
              )}
            </>
          )}
          {race.id === "6dfe2c7a-9111-4453-b3ac-a9dfe58f60d4" ? (
            <div className="xs:px-6 md:px-14 lg:px-28 mt-10">
              <p className="font-bold">
                Nedjelja, 25.08.2024. godine planirana je za još jedno druženje
                na Vilsonovom šetalištu. Start utrke je u 16 sati, a broj
                učesnika limitiran je na 100.
              </p>
              <p className="mt-4 font-bold">Startnina:</p>
              <p>
                - 18.06.2024. - 15.08.2024. : 30,00 KM (osnovni startni paket)
              </p>
              <p>
                - 18.06.2024. - 15.08.2024. : 40,00 KM (startni paket s majicom)
              </p>
              <p className="mt-4">
                Uplatu startnog paketa možete izvršiti direktno na žiro račun
                kod Intesa Sanpaolo Banke: <br></br>Primatelj: Udruženje DEČKI U
                PLAVOM, Gradačačka 28, Sarajevo, Bosna i Hercegovina<br></br>
                Račun: 1541802013305254 <br></br>Svrha: DONACIJA 1 / Ime i
                prezime (ako uplaćujete za ALKOHOLNU) <br></br>Svrha: DONACIJA 2
                / Ime i prezime (ako uplaćujete za BEZALKOHOLNU)
              </p>
              <p className="mt-4 font-bold">Podjela startnih paketa:</p>
              <p>
                Vilsonovo šetalište, zona start/cilj 25.08.2024. od 14:30 do
                15:45 sati
              </p>
              <p className="mt-4 font-bold">Pravilnik utrke:</p>
              <p>
                - morate biti punoljetni <br /> - sudjelovanje je ograničeno na
                100 sudionika <br /> - natjecatelji će biti podjeljeni po
                grupama <br /> - svaki natjecatelj treba popiti 4 piva od 0,33l
                i otrčati 4 kruga od 402,5m <br /> - starta se ispijanjem prvog
                piva, a prije početka svakog kruga pivo se mora popiti u zoni
                tranzicije od 15 metara od startne linije <br /> - natjecatelji
                moraju okrenuti bocu iznad glave kao dokaz da je pivo popijeno{" "}
                <br /> - natjecatelj mora završiti milju u 15 min
              </p>
              <p className="mt-4 font-bold">SUPERFINALE:</p>
              <p>
                - šest (6) najbržih natjecatelja ulaze u SUPERFINALE i trče
                dodatnu milju
              </p>
              <p className="mt-4 font-bold">Napomena za učesnike utrke:</p>
              <p className="mt-4 font-bold">
                ALKOHOL I VOŽNJA NIKADA NE IDU ZAJEDNO
              </p>
              <p className="mt-1">
                Nikada nemoj piti i voziti, pa tako niti poslije „BEER MILE”.
                Nikada nemojte biti putnik u automobilu koji vozi neko ko je pod
                dejstvom alkohola. Nikad ne dopustite da neko drugi vozi dok je
                pod dejstvom alkohola. Kad god planirate piti, isplanirajte
                siguran put kući. Ako je neko ko namjerava voziti popio previše
                alkohola, budite dobar prijatelj i osigurajte njegov siguran
                povratak kući.
              </p>
            </div>
          ) : (
            <>
              <p className="mt-10 xs:px-6 md:px-14 lg:px-28 text-xl">
                Start utrke / Završetak utrke:
              </p>
              <p className="xs:px-6 md:px-14 lg:px-28 text-blue-400 text-xl">
                Start of the race / End of the race:
              </p>
              {race.id === "994c24f4-9187-4385-9001-f9b4dff54211" && (
                <div className="mt-4 xs:px-6 md:px-14 lg:px-28">
                  <p>
                    Start: {race.race1.startDay.bih}, {race.startDate.bih}. u{" "}
                    {race.race1.startTime.bih}h
                  </p>
                  <p>
                    Vremenski limit:{" "}
                    {race.race1.raceLimit <= 10
                      ? race.race1.raceLimit + "h"
                      : race.race1.raceLimit + "min"}
                  </p>
                  <p className="text-blue-400 mt-1">
                    Start: {race.race1.startDay.eng}, {race.startDate.eng} at{" "}
                    {race.race1.startTime.eng}
                  </p>
                  <p className="text-blue-400">
                    Time limit:{" "}
                    {race.race1.raceLimit <= 10
                      ? race.race1.raceLimit + " hours"
                      : race.race1.raceLimit + " minutes"}
                  </p>
                </div>
              )}
              {race.race1 &&
                race.id !== "994c24f4-9187-4385-9001-f9b4dff54211" && (
                  <div className="mt-4 xs:px-6 md:px-14 lg:px-28">
                    {race.race1.raceLength ? (
                      <h3 className="text-lg font-bold">
                        Trka na {race.race1.raceLength}h
                      </h3>
                    ) : (
                      <h3 className="text-lg font-bold">
                        {race.race1.raceType} - {race.race1.raceSubtitle.bih}
                      </h3>
                    )}
                    <p>
                      Start: {race.race1.startDay.bih}, {race.startDate.bih}. u{" "}
                      {race.race1.startTime.bih}h
                    </p>
                    {race.race1.raceLimit && (
                      <p>
                        Vremenski limit:{" "}
                        {race.race1.raceLimit <= 10
                          ? race.race1.raceLimit + "h"
                          : race.race1.raceLimit + "min"}
                      </p>
                    )}
                    {race.race1.endDay && race.race1.endDate && (
                      <p>
                        Kraj: {race.race1.endDay.bih}, {race.race1.endDate.bih}.
                        u {race.race1.endTime.bih}h
                      </p>
                    )}
                    {race.race1.raceLength ? (
                      <h3 className="text-lg font-bold text-blue-400 mt-1">
                        {race.race1.raceLength}-hour race
                      </h3>
                    ) : (
                      <h3 className="text-lg font-bold text-blue-400 mt-1">
                        {race.race1.raceType} - {race.race1.raceSubtitle.eng}
                      </h3>
                    )}
                    <p className="text-blue-400">
                      Start: {race.race1.startDay.eng}, {race.startDate.eng} at{" "}
                      {race.race1.startTime.eng}
                    </p>
                    {race.race1.endDay && race.race1.endDate && (
                      <p className="text-blue-400">
                        End: {race.race1.endDay.eng}, {race.race1.endDate.eng}{" "}
                        at {race.race1.endTime.eng}
                      </p>
                    )}
                    {race.race1.raceLimit && (
                      <p className="text-blue-400">
                        Time limit:{" "}
                        {race.race1.raceLimit <= 10
                          ? race.race1.raceLimit + " hours"
                          : race.race1.raceLimit + " minutes"}
                      </p>
                    )}
                    {race.race2 && (
                      <div className="w-full h-[2px] bg-slate-300 my-2"></div>
                    )}
                  </div>
                )}
              {race.race2 &&
                race.id !== "994c24f4-9187-4385-9001-f9b4dff54211" && (
                  <div className="mt-4 xs:px-6 md:px-14 lg:px-28">
                    {race.race2.raceLength ? (
                      <h3 className="text-lg font-bold">
                        Trka na {race.race2.raceLength}h
                      </h3>
                    ) : (
                      <h3 className="text-lg font-bold">
                        {race.race2.raceType} - {race.race2.raceSubtitle.bih}
                      </h3>
                    )}
                    <p>
                      Start: {race.race2.startDay.bih}, {race.startDate.bih}. u{" "}
                      {race.race2.startTime.bih}h
                    </p>
                    {race.race2.raceLimit && (
                      <p>
                        Vremenski limit:{" "}
                        {race.race2.raceLimit <= 10
                          ? race.race2.raceLimit + "h"
                          : race.race2.raceLimit + "min"}
                      </p>
                    )}
                    {race.race2.endDay && race.race2.endDate && (
                      <p>
                        Kraj: {race.race2.endDay.bih}, {race.race2.endDate.bih}.
                        u {race.race2.endTime.bih}h
                      </p>
                    )}
                    {race.race2.raceLength ? (
                      <h3 className="text-lg font-bold text-blue-400 mt-1">
                        {race.race2.raceLength}-hour race
                      </h3>
                    ) : (
                      <h3 className="text-lg font-bold text-blue-400 mt-1">
                        {race.race2.raceType} - {race.race2.raceSubtitle.eng}
                      </h3>
                    )}
                    <p className="text-blue-400">
                      Start: {race.race2.startDay.eng}, {race.startDate.eng} at{" "}
                      {race.race2.startTime.eng}
                    </p>
                    {race.race2.endDay && race.race2.endDate && (
                      <p className="text-blue-400">
                        End: {race.race2.endDay.eng}, {race.race2.endDate.eng}{" "}
                        at {race.race2.endTime.eng}
                      </p>
                    )}
                    {race.race2.raceLimit && (
                      <p className="text-blue-400">
                        Time limit:{" "}
                        {race.race2.raceLimit <= 10
                          ? race.race2.raceLimit + " hours"
                          : race.race2.raceLimit + " minutes"}
                      </p>
                    )}
                    {race.race3 && (
                      <div className="w-full h-[2px] bg-slate-300 my-2"></div>
                    )}
                  </div>
                )}
              {race.race3 &&
                race.id !== "994c24f4-9187-4385-9001-f9b4dff54211" && (
                  <div className="mt-4 xs:px-6 md:px-14 lg:px-28">
                    {race.race3.raceLength ? (
                      <h3 className="text-lg font-bold">
                        Trka na {race.race3.raceLength}h
                      </h3>
                    ) : (
                      <h3 className="text-lg font-bold">
                        {race.race3.raceType} - {race.race3.raceSubtitle.bih}
                      </h3>
                    )}
                    <p>
                      Start: {race.race3.startDay.bih}, {race.startDate.bih}. u{" "}
                      {race.race3.startTime.bih}h
                    </p>
                    {race.race3.raceLimit && (
                      <p>
                        Vremenski limit:{" "}
                        {race.race3.raceLimit <= 10
                          ? race.race3.raceLimit + "h"
                          : race.race3.raceLimit + "min"}
                      </p>
                    )}
                    {race.race3.endDay && race.race3.endDate && (
                      <p>
                        Kraj: {race.race3.endDay.bih}, {race.race3.endDate.bih}.
                        u {race.race3.endTime.bih}h
                      </p>
                    )}
                    {race.race3.raceLength ? (
                      <h3 className="text-lg font-bold text-blue-400 mt-1">
                        {race.race3.raceLength}-hour race
                      </h3>
                    ) : (
                      <h3 className="text-lg font-bold text-blue-400 mt-1">
                        {race.race3.raceType} - {race.race3.raceSubtitle.eng}
                      </h3>
                    )}
                    <p className="text-blue-400">
                      Start: {race.race3.startDay.eng}, {race.startDate.eng} at{" "}
                      {race.race3.startTime.eng}
                    </p>
                    {race.race3.endDay && race.race3.endDate && (
                      <p className="text-blue-400">
                        End: {race.race3.endDay.eng}, {race.race3.endDate.eng}{" "}
                        at {race.race3.endTime.eng}
                      </p>
                    )}
                    {race.race3.raceLimit && (
                      <p className="text-blue-400">
                        Time limit:{" "}
                        {race.race3.raceLimit <= 10
                          ? race.race3.raceLimit + " hours"
                          : race.race3.raceLimit + " minutes"}
                      </p>
                    )}
                    {race.race4 && (
                      <div className="w-full h-[2px] bg-slate-300 my-2"></div>
                    )}
                  </div>
                )}
              {race.race4 && (
                <div className="mt-4 xs:px-6 md:px-14 lg:px-28">
                  {race.race4.raceLength ? (
                    <h3 className="text-lg font-bold">
                      Trka na {race.race4.raceLength}h
                    </h3>
                  ) : (
                    <h3 className="text-lg font-bold">
                      {race.race4.raceSubtitle.bih}
                    </h3>
                  )}
                  {race.id === "324e5d10-3ddf-4907-8653-c126a9bbed57" ? (
                    <p>
                      Start: {race.race4.startDay.bih}, {race.startDate.bih}
                    </p>
                  ) : (
                    <p>
                      Start: {race.race4.startDay.bih}, {race.startDate.bih}. u{" "}
                      {race.race4.startTime.bih}h
                    </p>
                  )}
                  {race.race4.raceLimit && (
                    <p>
                      Vremenski limit:{" "}
                      {race.race4.raceLimit <= 10
                        ? race.race4.raceLimit + "h"
                        : race.race4.raceLimit + "min"}
                    </p>
                  )}
                  {race.race4.endDay && race.race4.endDate && (
                    <p>
                      Kraj: {race.race4.endDay.bih}, {race.race4.endDate.bih}. u{" "}
                      {race.race4.endTime.bih}h
                    </p>
                  )}
                  {race.race4.raceLength ? (
                    <h3 className="text-lg font-bold text-blue-400 mt-1">
                      {race.race4.raceLength}-hour race
                    </h3>
                  ) : (
                    <h3 className="text-lg font-bold text-blue-400 mt-1">
                      {race.race4.raceSubtitle.eng}
                    </h3>
                  )}
                  {race.id === "324e5d10-3ddf-4907-8653-c126a9bbed57" ? (
                    <p className="text-blue-400">
                      Start: {race.race4.startDay.eng}, {race.startDate.eng}.
                    </p>
                  ) : (
                    <p className="text-blue-400">
                      Start: {race.race4.startDay.eng}, {race.startDate.eng} at{" "}
                      {race.race4.startTime.eng}
                    </p>
                  )}
                  {race.race4.endDay && race.race4.endDate && (
                    <p className="text-blue-400">
                      End: {race.race4.endDay.eng}, {race.race4.endDate.eng} at{" "}
                      {race.race4.endTime.eng}
                    </p>
                  )}
                  {race.race4.raceLimit && (
                    <p className="text-blue-400">
                      Time limit:{" "}
                      {race.race4.raceLimit <= 10
                        ? race.race4.raceLimit + " hours"
                        : race.race4.raceLimit + " minutes"}
                    </p>
                  )}
                </div>
              )}
              {race.imageMap && (
                <div className="w-full mt-12">
                  <ModalImage
                    small={race.imageMap}
                    large={race.imageMap}
                    hideDownload={true}
                    hideZoom={true}
                    className="w-full xs:h-[10rem] md:h-[22rem] lg:h-[30rem]"
                  />
                </div>
              )}
              {race.imageMap2 && (
                <div className="w-full mt-8">
                  <ModalImage
                    small={race.imageMap2}
                    large={race.imageMap2}
                    hideDownload={true}
                    hideZoom={true}
                    className="w-full xs:h-[10rem] md:h-[22rem] lg:h-[30rem]"
                  />
                </div>
              )}
              {race.imageMap3 && (
                <div className="w-full mt-8">
                  <ModalImage
                    small={race.imageMap3}
                    large={race.imageMap3}
                    hideDownload={true}
                    hideZoom={true}
                    className="w-full xs:h-[10rem] md:h-[22rem] lg:h-[30rem]"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleRace;
