import Container from "../components/Container";
import Sponsors from "../components/Sponsors";
import RaceCard from "../components/RaceCard";
import races from "../data/races";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>Dečki U Plavom - Home</title>
        <meta
          name="description"
          content="Snažno vjerujemo u viziju otvorenog i slobodnog trčanja na način da bude pristupačano svima."
        />
      </Helmet>
      <img
        className="hidden w-full md:h-[12rem] md:block lg:h-auto"
        alt="Hero"
        src={"/assets/BANNER_1.jpg"}
      />
      <div className="w-full md:hidden relative">
        <img
          className="w-full"
          alt="Hero"
          src={"/assets/mobile_naslovna.jpg"}
        />
        <img
          src={"/assets/logo_udruzenje.jpg"}
          alt="Logo"
          className="absolute w-20 m-auto inset-0"
        />
        <img
          src={"/assets/logo_text.png"}
          alt="Logo text"
          className="absolute w-72 m-auto top-32 inset-0"
        />
      </div>
      <div className="bg-[#eee] py-10">
        <Container>
          <div className="flex flex-col space-y-10 lg:flex-row lg:justify-between lg:space-y-0 lg:items-start lg:space-x-10 2xl:space-x-4">
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-7 gap-y-12">
              {races.map((race) => (
                <RaceCard key={race.id} race={race} />
              ))}
            </div>
            <div className="bg-gray-700 w-full md:w-2/3 md:mx-auto lg:w-[33%] xl:w-[25%] xs:py-10">
              <img
                src={"/assets/cr387.jpg"}
                alt="School"
                className="mx-auto px-4"
              />
              <div className="px-6 xs:mt-6 md:mt-10">
                <h1 className="uppercase xs:text-2xl sm:text-3xl font-bold text-white text-center lg:text-left">
                  Postani član
                </h1>
                <h1 className="uppercase xs:text-2xl sm:text-3xl font-bold text-white text-center lg:text-left">
                  CR387 community-a
                </h1>
                <h1 className="uppercase xs:text-2xl sm:text-3xl font-bold text-blue-400 text-center lg:text-left mt-1">
                  Become a member
                </h1>
                <h1 className="uppercase xs:text-2xl sm:text-3xl font-bold text-blue-400 text-center lg:text-left">
                  of CR387 community
                </h1>
                <div className="text-[0.92rem]">
                  <p className="text-white xs:pt-6 lg:pt-3 xs:text-center lg:text-left">
                    Budući da Sarajevo, glavni grad Bosne i Hercegovine, ima
                    potrebu za jakom i prepoznatljivom skupinom trkača odlučili
                    smo, po uzoru zemalja iz regije i svijeta, oformiti takvu
                    trkačku zajednicu.
                  </p>
                  <p className="text-blue-400 pt-1 xs:text-center lg:text-left">
                    Knowing that Sarajevo, the capital of Bosnia and
                    Herzegovina, needs a strong and recognizable group of
                    runners, following the example of countries in the region
                    and the World, we decided to form such running community.
                  </p>
                  <p className="text-white pt-[14px] xs:text-center lg:text-left">
                    Želiš postati dijelom „CAPITAL RUNNERS 387“ trkačke
                    zajednice i trenirati sa nama?
                  </p>
                  <p className="text-blue-400 pt-1 xs:text-center lg:text-left">
                    Do you want to become part of the "CAPITAL RUNNERS 387"
                    running community and train with us?
                  </p>
                  <p className="text-white pt-[14px] xs:text-center lg:text-left">
                    Naše programe članstva Team i Loyalty Club prilagodili smo
                    mogućnostima i ambicijama za svakog trkača.
                  </p>
                  <p className="text-blue-400 pt-1 xs:text-center lg:text-left">
                    We have adapted our Team and Loyalty Club membership
                    programs to the capabilities and ambitions of each runner.
                  </p>
                  <a
                    href="https://docs.google.com/forms/d/1CBVGjdlW2rEaxnEiR9wfpqn57WjqENY9cHn29pgl9X8/viewform?pli=1&chromeless=1&pli=1&edit_requested=true"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="bg-pink-500 duration-500 text-gray-700 w-full py-3 rounded-lg text-lg md:text-2xl font-semibold uppercase mt-12 hover:opacity-70 active:-translate-y-1">
                      Prijava / Registration
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* Sponsors */}
      <div className="bg-white xs:pt-10 xs:pb-14 md:pt-16 md:pb-20">
        <Container>
          <div className="flex flex-col items-center justify-center xs:mb-4 md:mb-10 lg:mb-14">
            <h1 className="uppercase xs:text-[1.4rem] sm:text-3xl font-bold z-30">
              Prijatelji udruženja
            </h1>
            <p className="h-2 bg-green-500 xs:w-[7.7rem] sm:w-[10.5rem] xs:mr-[8.5rem] sm:mr-[11.7rem] xs:-mt-3 sm:-mt-[10px] z-10"></p>
          </div>
          <Sponsors />
        </Container>
      </div>
    </div>
  );
};

export default Home;
