import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Sponsors = () => {
  const settings = {
    arrows: false,
    infinite: true,
    dots: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="pb-4">
      <div>
        <img
          src={"/assets/logo_carlsberg.png"}
          className="mx-auto w-56"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/logo_bhtelecom.png"}
          className="mx-auto w-48"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/logo_visit_sa.png"}
          className="mx-auto w-52"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/logo_zof.png"}
          className="mx-auto w-64"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/logo_sara.png"}
          className="mx-auto w-52"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/logo_argeta.png"}
          className="mx-auto w-[12.5rem]"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/logo_grad_sarajevo.png"}
          className="mx-auto w-60"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/logo_sec_one.png"}
          className="mx-auto w-44"
          alt="Sponsor"
        />
      </div>
      <div>
        <img
          src={"/assets/mozzart.png"}
          className="w-72 md:mx-auto xs:ml-20 sm:ml-4 md:ml-10"
          alt="Sponsor"
        />
      </div>
      <div>
        <img src={"/assets/osce.png"} className="mx-auto w-44" alt="Sponsor" />
      </div>
      <div>
        <img
          src={"/assets/peugeot.png"}
          className="mx-auto w-40"
          alt="Sponsor"
        />
      </div>
    </Slider>
  );
};

export default Sponsors;
