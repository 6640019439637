import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SingleRace from "./pages/SingleRace";
import Footer from "./components/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>Dečki U Plavom - Home</title>
        <meta
          name="description"
          content="Snažno vjerujemo u viziju otvorenog i slobodnog trčanja na način da bude pristupačano svima."
        />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/race/:raceId" element={<SingleRace />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
