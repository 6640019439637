import pdf1 from "../pdfs/VUM23_REZULTATI_6H.pdf";
import pdf2 from "../pdfs/VUM23_REZULTATI_12H.pdf";
import pdf3 from "../pdfs/VUM23_REZULTATI_24H.pdf";

const races = [
  {
    id: "f95da576-993a-4f8f-901b-781221382656",
    imageUrl: "/assets/trka_advent_nova.jpg",
    imageUrlPage: "/assets/trka_advent_page.jpg",
    title: "Advent Run",
    location: "Sarajevo - BH",
    startDate: {
      bih: "21. Decembar 2024",
      eng: "December 21, 2024",
    },
    race1: {
      startTime: {
        bih: "18:00",
        eng: "6 PM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      raceType: "5K",
      raceSubtitle: {
        bih: "ADVENT RUN",
        eng: "ADVENT RUN",
      },
      raceLimit: 45,
      prijavaLink: "https://stotinka.hr/hrv/utrka/6959/prijava_na_utrku",
    },
    description: {
      par1: {
        bih: "Kako bismo upotpunili zimsku i prazničnu idilu mjeseca decembra, Udruženje DEČKI U PLAVOM organizira prazničnu poslasticu za trkače i trkačice - peti sarajevski “ADVENT RUN”.",
        eng: "To fulfill the winter and holiday idyll of December Association DEČKI U PLAVOM is organizing a holiday treat for runners - the fifth „ADVENT RUN“.",
      },
      par2: {
        bih: "Nije bitno da li ste trkač ili šetać, najvažnije je da se družimo i uživamo u dobrom raspoloženju.",
        eng: "It doesn't matter whether you are runner or walker, the most important is to hang out and enjoy the great mood.",
      },
      par3: {
        bih: "Nakon utrke planiran je uzbudljiv muzički program u idiličnom zimskom ambijentu. Dođite da uživamo u koncertu, mirisima cimeta, vanilije, ukusnim kolačićima, čaju i kuhanom vinu.",
        eng: "After the race, an exciting musical program is planned in an idyllic winter setting. Come and enjoy the concert, the scents of cinnamon, vanilla, delicious cookies, tea and mulled wine.",
      },
      par4: {
        bih: "Trasa utrke: Zemaljski muzej BiH - Vilsonovo šetalište - Dolac Malta - Vilsonovo šetalište - Zemaljski muzej BiH",
        eng: "Race route: National Museum of BiH - Wilson Promenade - Dolac Malta - Wilson Promenade - National Museum of BiH",
      },
      par5: {
        bih: "Ukupno 5000 m zimske idile, blagdanskog ozračja, nezaboravnog provoda i urnebesnog slavlja koje će vas tjerati da se svake godine opet družimo.",
        eng: "A total of 5000m of winter idyll, holiday atmosphere, unforgettable fun and hilarious celebrations that will make you want to hang out again every year.",
      },
      heading: {
        bih: "Da li ste vi jedan od kostimiranih trkača na sarajevskom „ADVENT RUN?“. Najkreativniji kostim bit će nagrađen zasluženim peharom i nagradnim setom startnina za 2025. godinu.",
        eng: "Are you one of the costumed runners at the Sarajevo „ADVENT RUN?“. The most creative costume will be awarded with a well-deserved trophy and a prize set of entry fees for 2025.",
      },
    },
    imageMap: "/assets/trka_advent_mapa.png",
  },
  {
    id: "df25b06b-e510-4dba-2690-18a65a1d93b7",
    imageUrl: "/assets/trka_unusual_2.jpg",
    imageUrlPage: "/assets/trka_unusual_page.png",
    title: "Unusual Marathon",
    location: "Sarajevo - BH",
    startDate: {
      bih: "11. Januar 2025",
      eng: "Januar 11, 2025",
    },
    race1: {
      startTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      endTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      raceType: "42K",
      raceSubtitle: {
        bih: "MARATON",
        eng: "MARATHON",
      },
      raceLimit: 5,
      prijavaLink: "https://www.stotinka.hr/hrv/utrka/7151/prijava_na_utrku",
    },
    race2: {
      startTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      endTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      raceType: "21K",
      raceSubtitle: {
        bih: "POLA MARATONA",
        eng: "HALF MARATHON",
      },
      raceLimit: 3,
      prijavaLink: "https://www.stotinka.hr/hrv/utrka/7152/prijava_na_utrku",
    },
    race3: {
      startTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      endTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      raceType: "10,5K",
      raceSubtitle: {
        bih: "ČETVRTINA MARATONA",
        eng: "QUARTER MARATHON",
      },
      raceLimit: 90,
      prijavaLink: "https://www.stotinka.hr/hrv/utrka/7153/prijava_na_utrku",
    },
    description: {
      par1: {
        bih: "Dobrodošli na sedmo izdanje maratonske utrke “UNUSUAL MARATHON”.",
        eng: "Welcome to the 7th edition of the marathon race “UNUSUAL MARATHON”.",
      },
      par2: {
        bih: "Januar je pravo vrijeme da se započne sezona utrka u Sarajevu. Izaberite šta vam više odgovara da istrčite po ekstremnim uvjetima koji tada vladaju u gradu, a to su snijeg, led, smog, magla i prosječna temperatura od oko -7 stupnjeva. Cijeli maraton, pola maratona ili frtalj maratona?",
        eng: "The January is the right time to start the race season in Sarajevo. Choose the ideal race for yourself in the extreme conditions in the city, namely snow, ice, smog, fog and an average temperature of about -7 degrees. A full marathon, half marathon or quarter marathon?",
      },
      par3: {
        bih: "Utrka ima start i cilj na Vilsonovom šetalištu. Jedan krug je dužine 10548,75m (frtalj maratona), a trasa je: Vilsonovo šetalište, Dolac Malta, Čengić vila, Alipašino polje, Nedžarići, gdje je i okret, i povratak na Vilsonovo šetalište, natrag do cilja.",
        eng: "The race has a start and finish at “Vilsonovo šetalište”. One lap is 10548.75m long (quarter marathon), and the route is: Vilsonovo šetalište, Dolac Malta, Čengić vila, Alipašino polje, Nedžarići, where the turn is, and back to the finish point at Vilsonovo šetalište",
      },
      // heading: {
      //   bih: "Da odmah odgovorimo na pitanje da li je zdravo trčati zimi po Sarajevu čiji je zrak u to doba godine često među top tri najzagađenija u Europi… NIJE zdravo, ali… trčite s razlogom i zagovarate borbu za čist zrak.",
      //   eng: "Let’s immediately answer the question whether it is healthy to run in Sarajevo during the winter, if the air at that time of year is often among the top three most polluted in Europe... IT'S NOT HEALTHY, but... you run with a purpose and advocate for clean air.",
      // },
    },
    // imageMap: "/assets/trka_unusual_mapa.jpg",
  },
  {
    id: "324e5d10-3ddf-4907-8653-c126a9bbed57",
    imageUrl: "/assets/trka_sarajevo_marathon.jpg",
    imageUrlPage: "/assets/trka_sarajevo_page.jpg",
    title: "Sarajevo Marathon",
    location: "Sarajevo - BH",
    startDate: {
      bih: "11. Maj 2025",
      eng: "May 11, 2025",
    },
    race1: {
      startTime: {
        bih: "8:00",
        eng: "8 AM",
      },
      startDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      raceType: "42K",
      raceSubtitle: {
        bih: "MARATON",
        eng: "MARATHON",
      },
      raceLimit: 5,
      prijavaLink: "https://stotinka.hr/hrv/utrka/6740/prijava_na_utrku",
    },
    race2: {
      startTime: {
        bih: "8:00",
        eng: "8 AM",
      },
      startDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      raceType: "21K",
      raceSubtitle: {
        bih: "POLUMARATON",
        eng: "HALF MARATHON",
      },
      raceLimit: 3,
      prijavaLink: "https://stotinka.hr/hrv/utrka/6741/prijava_na_utrku",
    },
    race3: {
      startTime: {
        bih: "8:00",
        eng: "8 AM",
      },
      startDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      raceType: "5K",
      raceSubtitle: {
        bih: "MEMORIJALNA UTRKA “OSMAN JUNUZOVIĆ”",
        eng: "MEMORIAL RACE “OSMAN JUNUZOVIĆ”",
      },
      raceLimit: 45,
      prijavaLink: "https://stotinka.hr/hrv/utrka/6742/prijava_na_utrku",
    },
    race4: {
      startTime: {
        bih: "12:00",
        eng: "12 AM",
      },
      startDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      raceType: "460m (KIDS RUN)",
      raceSubtitle: {
        bih: "460m - KIDS RUN",
        eng: "460m - KIDS RUN",
      },
      raceLimit: 45,
      // prijavaLink:
      //   "https://docs.google.com/forms/d/e/1FAIpQLSdr42cEVxFW-t00ivjx_WFbHrDWcOGQc3rMN9GXIEquKFJmLQ/viewform?vc=0&c=0&w=1&flr=0",
    },
    description: {
      par1: {
        bih: "Dobrodošli na šesto izdanje maratonske utrke “SARAJEVO MARATHON”.",
        eng: "Welcome to the sixth edition of “SARAJEVO MARATHON”.",
      },
      par2: {
        bih: "SARAJEVSKI MARATON nastavlja gdje je stao prošle godine.",
        eng: "“SARAJEVO MARATHON”  continues where it stopped last year.",
      },
      par4: {
        bih: "11. maj će biti pravi praznik trčanja u Sarajevu.",
        eng: "May 11th will be a true running holiday in Sarajevo.",
      },
      par5: {
        bih: "Start maratonske utrke (42k) je sa trga Vijećnica, te nastavlja glavnom gradskom saobraćajnicom smjer Baščaršija – Skenderija – Marijin dvor – Pofalići – Dolac malta – Otoka – Alipašin most – Stup – Vrelo Bosne – Velika aleja – Hrasnica – Vojkovići – Kotorac – Lukavica – Dobrinja – Mojmilo – Švrakino selo – stadion Grbavica – Skenderija – Drvenija, te je cilj na trgu Vijećnica. Okrepe su osigurane na 14 mjesta duž staze.",
        eng: "The start of the marathon (42k) race is from the Town Hall Square and it continues through the main road towards Baščaršija – Skenderija – Marijin dvor – Pofalići – Dolac malta – Otoka – Alipašin most – Stup – Vrelo Bosne – Velika aleja – Hrasnica – Vojkovići – Kotorac – Lukavica – Dobrinja – Mojmilo – Švrakino selo – Stadium “Grbavica” – Skenderija – Drvenija, and the finish point is at the Town Hall Square. Refreshment points are located on 14 places along the track.",
      },
      par6: {
        bih: "Start polumaratonske utrke (21k) je sa trga Vijećnica, te nastavlja glavnom gradskom saobraćajnicom smjer Baščaršija – Skenderija – Marijin dvor – Pofalići – Dolac malta – Otoka – Alipašin most – Stup– Vrelo Bosne – Velika aleja, te je cilj u Hrasnici. Okrepe su osigurane na 7 mjesta duž staze.",
        eng: "The start of the half marathon (21k) race is from the Town Hall Square and it continues through the main road in the direction Baščaršija – Skenderija – Marijin dvor – Pofalići – Dolac malta – Otoka – Alipašin most – Stup– Vrelo Bosne – Velika aleja and the finish point is at the Hrasnica. Refreshment points are located on 7 places along the track.",
      },
      par7: {
        bih: "Start Memorijalne utrke ”Osman Junuzović” (5K) je sa trga Vijećnica, te nastavlja glavnom gradskom saobraćajnicom smjer Baščaršija – Skenderija – Marijin dvor – Pofalići, te je cilj na Čengić vili.",
        eng: "The start of the Memorial race “Osman Junuzović” (5) is from the Town Hall Square and it continues through the main road towards Baščaršija – Skenderija – Marijin dvor – Pofalići, and the finish points is at Čengić vila.",
      },
      heading: {
        bih: "Pobjednici prvog izdanja “SARAJEVO MARATON” koje je održano 2020. godine bili su: u muškoj konkurenciji Osman Junuzović sa vremenom 2:30:10, a u ženskoj kategoriji Katarina Pohlod sa vremenom 3:06:16",
        eng: "Winners of the first SARAJEVO MARATHON edition from 2020 were: male category Osman Junuzović (2:30:10) and female category Katarina Pohold (3:06:16).",
      },
    },
    // imageMap: "/assets/trka_sarajevo_mapa1.jpg",
    // imageMap2: "/assets/trka_sarajevo_mapa2.png",
    // imageMap3: "/assets/trka_sarajevo_mapa3.png",
  },
  {
    id: "6dfe2c7a-9111-4453-b3ac-a9dfe58f60d4",
    imageUrl: "/assets/pivska_milja.jpg",
    imageUrlPage: "/assets/pivska_milja_page.png",
    title: "Beer Mile",
    location: "Sarajevo - BH",
    startDate: {
      bih: "25. August 2024",
      eng: "August 25, 2024",
    },
    race1: {
      startTime: {
        bih: "16:00",
        eng: "4 PM",
      },
      endTime: {
        bih: "?",
        eng: "?",
      },
      startDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      endDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      endDate: {
        bih: "6. Oktobar 2024",
        eng: "October 6, 2024",
      },
      raceType: "CARLSBERG BEER MILE 5% alc",
      prijavaLink: "https://stotinka.hr/hrv/utrka/6832/prijava_na_utrku",
      // prijavaLink: pdf3,
    },
    race2: {
      startTime: {
        bih: "16:00",
        eng: "4 PM",
      },
      endTime: {
        bih: "16:00",
        eng: "4 PM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      endDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      endDate: {
        bih: "6. Oktobar 2024",
        eng: "October 6, 2024",
      },
      raceType: "SOMERSBY MILE 0,00% alc",
      prijavaLink: "https://stotinka.hr/hrv/utrka/6833/prijava_na_utrku",
      // prijavaLink: pdf2,
    },
    description: {
      par1: {
        bih: `Dobrodošli na drugo izdanje sportske manifestacije „CARLSBERG BEER MILE” Sarajevo.`,
        // eng: "Welcome to the third edition of the Sarajevo ultramarathon race ”WILS ULTRAMARATHON”.",
      },
      par2: {
        bih: "Za razliku od prvog izdanja, ove godine Vam pored ALKOHOLNE CARLSBERG PIVSKE MILJE nudimo i BEZALKOHOLNU SOMERSBY 0,00% MILJU.",
      },
      par3: {
        bih: "Pivska milja je spoj atletske utrke i pivske zabave. Da biste završili ovu utrku nisu vam potrebne samo brze noge, već i bistra glava nakon nekoliko ispijenih piva. Ovakve utrke uvijek imaju veseli početak i još veseliji završetak. Idealan je spoj za osobe koje vole piti pivo i uživati u trčanju.",
        // eng: "An ultramarathon is challenging yourself, exploring endurance and the curse of falling in love with every millimeter of asphalt run. Perfect for those who want to rise from a half marathon or marathon, to an ultramarathon.",
      },
      heading: {
        bih: "Pobjednik prvog izdanja je Igor Vuković koji je kvalifikacionu milju (popio 4 piva i istrčao 4x400m) za ukupno 6 minuta i 41 sekundu, dok je u finalnu milju završio za 7 minuta i 20 sekundi.",
        // eng: "The winner of the first edition is Igor Vuković, who completed the qualifying mile (drank 4 beers and ran 4x400m) in a total of 6 minutes and 41 seconds, while he finished the final mile in 7 minutes and 20 seconds.",
      },
    },
  },
  {
    id: "7c66f599-92a9-4f41-a731-f5f19ab7a4b8",
    imageUrl: "/assets/trka_srebrenica.jpg",
    imageUrlPage: "/assets/trka_srebrenica_page.jpg",
    title: "Stazama Bosne srebrene",
    location: "Srebrenica - BH",
    startDate: {
      bih: "08. Septembar 2024",
      eng: "September 8, 2024",
    },
    race1: {
      startTime: {
        bih: "12:00",
        eng: "12 AM",
      },
      startDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      raceType: "10K",
      raceSubtitle: {
        bih: "STAZAMA BOSNE SREBRENE",
        eng: "SREBRENICA RUN",
      },
      raceLimit: 90,
      prijavaLink: "https://www.stotinka.hr/hrv/utrka/6958/prijava_na_utrku",
    },
    description: {
      par1: {
        bih: "Dobrodošli na četvrto izdanje utrke „STAZAMA BOSNE SREBRENE“ Srebrenica.",
        eng: "Welcome to the fourth edition of the race „Srebrenica Run“.",
      },
      par2: {
        bih: "„STAZAMA BOSNE SREBRENE“ je cestovna utrka na 10 000m koju Udruženje DEČKI U PLAVOM organizira u nedjelju 08.09.2024. godine sa početkom u 12:00 sati u Srebrenici. Dođite, trčite, uživajte… Istražite bosanskohercegovačku istoriju. Dajte svoj doprinos povratku sportskih sadržaja i sportskih takmičenja u Srebrenicu. Utrka ima AIMS certifikat.",
        eng: "It is a 10 000m road race organized by the Association DEČKI U PLAVOM on Sunday, September 8, 2024 starting at 12:00 in Srebrenica. Come, run, enjoy... Explore the history of Bosnia and Herzegovina. Give your contribution to the return of sports content and sports competitions to Srebrenica. The race is AIMS certified.",
      },
      par3: {
        bih: "Pored utrke na 10000m organiziramo i „SBS - DJEČIJA UTRKA“ na 600m. Utrka je namjenjena za djecu uzrasta od 6 do 14 godina, i biti će bodovana u više starosnih kategorija. Uzrast od 6 do 8 godina, 9 do 11 godina i 12 do 14 godina, te posebno kategorije za dječake i djevojčice.",
        eng: "In addition to the 10 000m race, we also organize the „SBS - KIDS RACE“ 600m. The race is for children aged 6 to 14, and will be scored in several age categories. Age from 6 to 8 years, 9 to 11 years and 12 to 14 years, and special categories for boys and girls.",
      },
      par4: {
        bih: "Start dječije utrke je planiran za 11:00 sati kod Robne kuće, Srebrenica.",
        eng: "The start of the kids race is planned for 11:00 a.m. at the Department Store, Srebrenica.",
      },
      heading: {
        bih: "Na utrci je strogo zabranjeno bilo kakvo političko, nacionalno i slično obilježje. Neće biti političkih govora i sl. Podjelama bar taj jedan dan u Srebrenici nema mjesta.",
        eng: "Any political, national or similar symbols are strictly prohibited at the race. There will be no political speeches etc. There is no room for divisions in Srebrenica for at least one day.",
      },
    },
    imageMap: "/assets/trka_srebrenica_mapa.png",
  },
  {
    id: "74a6c5c0-e049-ef0f-d8f1-a6dd0b702152",
    imageUrl: "/assets/trka_vils.jpg",
    imageUrlPage: "/assets/trka_vils_page.png",
    title: "Vils Ultramarathon",
    location: "Sarajevo - BH",
    startDate: {
      bih: "5. Oktobar 2024",
      eng: "October 5, 2024",
    },
    race1: {
      startTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      endTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      endDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      endDate: {
        bih: "6. Oktobar 2024",
        eng: "October 6, 2024",
      },
      raceLength: "24",
      prijavaLink: "https://stotinka.hr/hrv/utrka/6346/prijava_na_utrku",
      // prijavaLink: pdf3,
    },
    race2: {
      startTime: {
        bih: "21:00",
        eng: "9 PM",
      },
      endTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      endDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      endDate: {
        bih: "6. Oktobar 2024",
        eng: "October 6, 2024",
      },
      raceLength: "12",
      prijavaLink: "https://stotinka.hr/hrv/utrka/6345/prijava_na_utrku",
      // prijavaLink: pdf2,
    },
    race3: {
      startTime: {
        bih: "9:00",
        eng: "9 AM",
      },
      endTime: {
        bih: "15:00",
        eng: "3 PM",
      },
      startDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      endDay: {
        bih: "Subota",
        eng: "Saturday",
      },
      endDate: {
        bih: "5. Oktobar 2024",
        eng: "October 5, 2024",
      },
      raceLength: "6",
      prijavaLink: "https://stotinka.hr/hrv/utrka/6347/prijava_na_utrku",
      // prijavaLink: pdf1,
    },
    description: {
      par1: {
        bih: "Dobrodošli na treće izdanje sarajevske ultramaratonske utrke “VILS ULTRAMARATON”.",
        eng: "Welcome to the third edition of the Sarajevo ultramarathon race ”WILS ULTRAMARATHON”.",
      },
      par2: {
        bih: "Ultramaraton je izazivanje sebe, istraživanje sposobnosti izdržljivosti i prokletstvo zaljubljenosti u svaki milimetar pretrčanog asfalta. Savršeno za one koji se žele uzdići od polumaratona ili maratona, do ultramaratona.",
        eng: "An ultramarathon is challenging yourself, exploring endurance and the curse of falling in love with every millimeter of asphalt run. Perfect for those who want to rise from a half marathon or marathon, to an ultramarathon.",
      },
      par3: {
        bih: "Trčat ćete na prekrasnoj slikovitoj lokaciji na utrci od 6, 12, 24 sata. Vilsonovo šetalište pruža nezaboravno iskustvo trčanja koje će zasigurno postati omiljeno!",
        eng: "You will run in a beautiful picturesque location at a race of 6, 12, 24 hours. Wilson's Promenade provides an unforgettable running experience that will surely become a favorite!",
      },
      par4: {
        bih: "Staza je ravna, brza, 2000 metara po krugu, certifikovana od strane IAAF-AIMS.",
        eng: "The track is straight, fast, 2000 meters per lap, certified by the IAAF-AIMS.",
      },
      heading: {
        bih: "Svjetski rekord na 24 sata je 262,190 km za žene, koje je 2018. postavila Camille Herron iz SAD-a, i 319.614 km za muškarce, koje je postavio Aleksandr Sorokin iz Litve 2022.",
        eng: "The 24-hour world record is 262,190 km for women, set in 2018 by Camille Herron from US, and 319,614 km for men, set by Aleksandr Sorokin from Lithuania in 2022",
      },
    },
    imageMap: "/assets/trka_vils_mapa.png",
  },
  // {
  //   id: "04b6d26f-0edd-47d6-8729-41c517f3ba16",
  //   imageUrl: "/assets/trka_beer.jpg",
  //   imageUrlPage: "/assets/trka_beer_page.jpg",
  //   title: "BEER MILE - PIVSKA MILJA",
  //   location: "Sarajevo - BH",
  //   startDate: {
  //     bih: "23. August 2023",
  //     eng: "August 23, 2023",
  //   },
  //   race1: {
  //     startTime: {
  //       bih: "19:00",
  //       eng: "7 PM",
  //     },
  //     startDay: {
  //       bih: "Srijeda",
  //       eng: "Wednesday",
  //     },
  //     raceType: "1610m + 4 piva/beer",
  //     raceSubtitle: {
  //       bih: "",
  //       eng: "",
  //     },
  //     raceLimit: 15,
  //     prijavaLink: "https://stotinka.hr/hrv/utrka/5924/prijava_na_utrku",
  //   },
  //   description: {
  //     par1: {
  //       bih: "Dobrodošli na prvo izdanje najzabavnije utrke „BEER MILE - PIVSKA MILJA“.",
  //       eng: "Welcome to the first edition of the most fun race „BEER MILE“.",
  //     },
  //     par2: {
  //       bih: "Broj učesnika je ograničen na 100.",
  //       eng: "The number of participants is limited to 100.",
  //     },
  //     par3: {
  //       bih: `Trasa staze: Vilsonovo šetalište - Koste Hermana - Franje Račkog - Vilsonovo šetalište`,
  //       eng: `Route: Vilsonovo šetalište - Koste Hermana - Franje Račkog - Vilsonovo šetalište`,
  //     },
  //     heading: {
  //       bih: `ALKOHOL I VOŽNJA NIKADA NE IDU ZAJEDNO. Nikada nemoj piti i voziti, pa tako niti poslije „BEER MILE“. Nikada nemojte biti putnik u automobilu koji vozi neko ko je pod dejstvom alkohola. Nikad ne dopustite da neko drugi vozi dok je pod dejstvom alkohola. Kad god planirate piti, isplanirajte siguran put kući. Ako je neko ko namjerava voziti popio previše alkohola, budite dobar prijatelj i osigurajte njegov siguran povratak kući.`,
  //       eng: "ALCOHOL AND DRIVING NEVER GO TOGETHER. Never drink and drive, not even after the „BEER MILE“. Never be a passenger in a car driven by someone who is under the influence of alcohol. Never let someone else drive while under the influence of alcohol. Whenever you plan to drink, plan a safe way home. If someone who is going to drive has had too much alcohol, be a good friend and make sure they get home safely.",
  //     },
  //   },
  // },
  //   {
  //     id: "994c24f4-9187-4385-9001-f9b4dff54211",
  //     imageUrl: "/assets/trka_pedalanje.jpg",
  //     imageUrlPage: "/assets/trka_pedalanje_page.png",
  //     title: "Pedalanje i Trčkaranje",
  //     location: "Sarajevo - BH",
  //     startDate: {
  //       bih: "24. Septembar 2023",
  //       eng: "September 24, 2023",
  //     },
  //     race1: {
  //       startTime: {
  //         bih: "08:00",
  //         eng: "8 AM",
  //       },
  //       startDay: {
  //         bih: "Nedjelja",
  //         eng: "Sunday",
  //       },
  //       raceType: "MUŠKI TIM",
  //       raceSubtitle: {
  //         bih: "",
  //         eng: "",
  //       },
  //       raceLimit: 300,
  //       prijavaLink: "https://stotinka.hr/hrv/utrka/5830/prijava_na_utrku",
  //     },
  //     race2: {
  //       startTime: {
  //         bih: "08:00",
  //         eng: "8 AM",
  //       },
  //       startDay: {
  //         bih: "Nedjelja",
  //         eng: "Sunday",
  //       },
  //       raceType: "ŽENSKI TIM",
  //       raceSubtitle: {
  //         bih: "",
  //         eng: "",
  //       },
  //       raceLimit: 300,
  //       prijavaLink: "https://stotinka.hr/hrv/utrka/5831/prijava_na_utrku",
  //     },
  //     race3: {
  //       startTime: {
  //         bih: "08:00",
  //         eng: "8 AM",
  //       },
  //       startDay: {
  //         bih: "Nedjelja",
  //         eng: "Sunday",
  //       },
  //       raceType: "MJEŠOVITI TIM",
  //       raceSubtitle: {
  //         bih: "",
  //         eng: "",
  //       },
  //       raceLimit: 300,
  //       prijavaLink: "https://stotinka.hr/hrv/utrka/5832/prijava_na_utrku",
  //     },
  //     description: {
  //       par1: {
  //         bih: "Dobrodošli na prvo izdanje rekreativne duatlonske utrke „PEDALANJE&TRČKARANJE“ Sarajevo/Trebević.",
  //         eng: "Welcome to the first edition of the recreational duathlon race „PEDALING&RUNNING“ Sarajevo/Trebević.",
  //       },
  //       par2: {
  //         bih: "„PEDALANJE&TRČKARANJE“ je kombinacija veoma zahtjevne MTB biciklističke utrke na 14250m sa elevacijom + 791m i trail utrke na 5000m (2500m sa elevacijom + 361m i  2500 sa elevacijom – 361m) koju Udruženje DEČKI U PLAVOM organizira u nedjelju 24.09.2023. godine sa početkom u 8:00 sati. Vremenski limit za MTB biciklističku dionicu je 200 min, a za trail dionicu 100 min.",
  //         eng: "„PEDALING&RUNNING“ is a team race: it's a combination of MTB cycling and trail which will be organized by the Association “BOYS IN BLUE” on Sunday, 24 September 2023 at 8 a.m. The first part consists of a demanding 14.25k MTB cycling race with the elevation of +791m, and the time limit of 200 minutes. The second part is a 5k trail race (2.5k with the elevation of +361m and 2.5k with the elevation of -361m) with the time limit of 100 minutes.",
  //       },
  //       par3: {
  //         bih: `Broj učesnika je ograničen na 100 timova, a tim čine MTB biciklist i trail trkač:
  // 50 timova: muški+ženski
  // 25 timova: ženski+ženski
  // 25 timova: muški+muški\n`,
  //         eng: `The team consists of an MTB cyclist and a trail runner. The number of participants is limited to 100 teams:
  // 50 teams: male+female
  // 25 teams: female+female
  // 25 teams: male+male`,
  //       },
  //       par4: {
  //         bih: `Trasa biciklističke staze:
  // Trg Vijećnica, Bentbaša, Kozija ćuprija, Jarčedoli (kontrolna tačka/okrepa), Iza gaja, Dolovi, Apelova cesta (kontrolna tačka/okrepa), Vidikovac, Brus, planinarski dom Čelina. Staza je vrlo zahtjevna i preporuka je da se koristi isključivo MTB bicikl, te da učesnik bude fizički spreman za velike napore i uzbrdice. Na trasi će biti dvije (2) okrepne stanice.`,
  //         eng: `Cycling route:
  // Vijećnica square, Bentbaša, Goat’s bridge, Jarčedoli (checkpoint/refreshment), Iza gaja, Dolovi, Apel road (checkpoint/refreshment), Viewpoint - Vidikovac, Brus, Mountain lodge Čelina. The road is considered as demanding and it is recommended to use only MTB bikes. The participants need to be physically ready for riding uphills. There will be two (2) refreshment stations on the route.`,
  //       },
  //       par5: {
  //         bih: `Trasa trail staze:
  // Dom Čelina, Puhova ravan, Istočni greben, Vrh Trebevića (kontrolna tačka/okrepa), Istočni greben, Puhova ravan, Dom Čelina. Trasa je okarakterizirana kao lagana.`,
  //         eng: `Trail route:
  // Mountain lodge Čelina, Puhova Ravan, east ridge, Vrh Trebevića (checkpoint/refreshment), east ridge, Puhova Ravan, Mountain lodge Čelina. The route is characterized as easy.`,
  //       },
  //       heading: {
  //         bih: "U sklopu projekta organiziramo akciju podrške GSS KS i GSS Istočni Stari Grad koji preko cijele godine brinu o sigurnosti posjetitelja pluća Sarajeva, našeg Trebevića, te će na lokalitetu preuzimanja učeničkih paketa biti postavljena njihova „kutija“ za donacije. Preporučeni iznos donacije po timu je 30,00 KM kako bismo prikupili planiranih oko 3000,00 KM.",
  //         eng: "Part of the project is organizing an action to support the Mountain Rescue services of Canton Sarajevo and Istočni Stari Grad, who take care of safety of visitors who come to visit the lungs of Sarajevo, our Trebević mountain, throughout the year. The donationa box for this purpose will be placed at the bib pick up location. The recommended amount of donation per team is 30 BAM in order to collect the planned 3000 BAM.",
  //       },
  //       imageMap: "/assets/trka_reciklaza_mapa.png",
  //     },
  //   },
  {
    id: "9a725235-dd54-4d88-a5d8-4d1e2bc737d9",
    imageUrl: "/assets/trka_reciklaza.jpg",
    imageUrlPage: "/assets/trka_reciklaza_page.jpg",
    title: "Trčim. Uživam. Recikliram.",
    location: "Sarajevo - BH",
    startDate: {
      bih: "2. Juni 2024",
      eng: "June 2, 2024",
    },
    race1: {
      startTime: {
        bih: "10:00",
        eng: "10 AM",
      },
      startDay: {
        bih: "Nedjelja",
        eng: "Sunday",
      },
      raceType: "6K",
      raceSubtitle: {
        bih: "TRČIM. UŽIVAM. RECIKLIRAM.",
        eng: "RUN. ENJOY. RECYCLE.",
      },
      raceLimit: 45,
      prijavaLink: "https://www.stotinka.hr/hrv/utrka/6603/prijava_na_utrku",
    },
    description: {
      par1: {
        bih: "Dobrodošli na šesto izdanje sportsko-ekološke utrke “TRČIM. UŽIVAM. RECIKLIRAM”.",
        eng: "Welcome to the sixth edition of the sports and environmental race “RUN. ENJOY. RECYCLE”.",
      },
      par2: {
        bih: "Radi se o projektu kojim želimo ukazati na ekološke probleme u našem društvu, s akcentom na promjenu ponašanja kad je u pitanju reciklaža, potreba za recikliranjem, kao i mogućnosti zaštite okoliša putem recikliranja. Organiziranjem prve utrke ovog tipa, Udruženje DEČKI U PLAVOM osiguralo je da se Sarajevo nađe među europskim gradovima koji organiziraju ovaj vid promocije ekološke osvještenosti. Mišljenja smo da će, osim promjene ponašanja u vezi sa ekologijom i potrebom reciklaže, ova utrka doprinijeti i intenzivnijoj i široj promociji Sarajeva, te ujedno promovirati zdrave životne navike i sport.",
        eng: "With this project we want to point out environmental problems in our society, with an emphasis on changing behavior when it comes to recycling, the need for recycling, as well as the possibilities of environmental protection through recycling. By organizing the first race of this type, the Association has ensured that Sarajevo is among the European cities which organize this type of promotion of environmental awareness. In addition to changing behavior related to ecology and the need for recycling, we think this race will contribute to a more intensive and wider promotion of Sarajevo and at the same time promote healthy lifestyle habits and sports.",
      },
      heading: {
        bih: "Prikupljena PET ambalaža će biti preuzeta i reciklirana. Na ovaj način želimo uticati na promjenu ponašanja građana kad je u pitanju recikliranje, te uticati na veću aktivnost na ovom polju.",
        eng: "The collected PET packaging will be taken over and recycled. In this way, we  want to influence the change in the behavior of citizens when it comes to recycling and influence greater activity in this field.",
      },
    },
    imageMap: "/assets/trka_reciklaza_mapa.png",
  },
];

export default races;
