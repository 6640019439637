import { GrFacebookOption, GrInstagram } from "react-icons/gr";
import { AiOutlineMail } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "./Container";

const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const routeMatch = (url) => {
    if (pathname === url) return true;
  };

  const checkRoute = () => {
    if (routeMatch("/")) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    } else {
      navigate("/");
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <div className="bg-[#222] py-10">
      <Container>
        <div className="flex flex-col-reverse 2xl:flex-row 2xl:space-x-[10.5%] 3xl:space-x-[14%]">
          <div className="text-white 2xl:ml-20 text-center 2xl:text-left">
            <h2 className="xs:text-xl sm:text-2xl font-bold mt-10 2xl:mt-0">
              Udruženje DEČKI U PLAVOM
            </h2>
            <p className="xs:text-base sm:text-lg">
              Gradačačka 28, 71000 Sarajevo
            </p>
            <p className="xs:text-base sm:text-lg">Bosna i Hercegovina</p>
            <p className="xs:text-base sm:text-lg">Telefon: +387 62 684 700</p>
            <p className="mt-4 xs:text-base sm:text-lg">
              Intesa Sanpaolo Banka:{" "}
              <span className="font-bold">1541802013305254</span>
            </p>
            <p className="xs:text-base sm:text-lg">
              ID broj: <span className="font-bold">4202753670005</span>
            </p>
          </div>
          <div>
            <div className="text-center xs:text-3xl sm:text-4xl font-bold">
              <span className="text-white">#</span>
              <span className="text-green-500">JERNAMJESTALO</span>
            </div>
            <div className="flex justify-center items-center space-x-8 mt-7">
              <a
                href="https://www.facebook.com/decki.uplavom.9"
                target="_blank"
                rel="noreferrer"
              >
                <GrFacebookOption
                  size={25}
                  className="text-gray-300 hover:text-green-400 hover:scale-110 cursor-pointer duration-300"
                />
              </a>
              <a
                href="https://www.instagram.com/deckiuplavom"
                target="_blank"
                rel="noreferrer"
              >
                <GrInstagram
                  size={25}
                  className="text-gray-300 hover:text-green-400 hover:scale-110 cursor-pointer duration-300"
                />
              </a>
              <a href="mailto:deckiuplavom@gmail.com">
                <AiOutlineMail
                  size={25}
                  className="text-gray-300 hover:text-green-400 hover:scale-110 cursor-pointer duration-300"
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className="bg-[#111] w-full py-6 mt-14">
        <div className="flex justify-center items-center space-x-10 text-gray-300 uppercase font-semibold">
          <div>
            <p
              onClick={checkRoute}
              className="text-white font-bold text-center z-20 cursor-pointer hover:opacity-80 duration-300"
            >
              Home
            </p>
            <p className="h-1 bg-green-500 w-[3.7rem] -mt-1.5 z-10"></p>
          </div>
        </div>
      </div>
      <p className="mt-6 text-gray-400 text-center">
        &copy; Copyright 2022. All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
