import { BiRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";

const RaceCard = ({ race }) => {
  return (
    <div className="group">
      <div className="relative group cursor-pointer transition-all">
        <img src={race.imageUrl} alt="Race" className="group-hover:opacity-90 group-hover:scale-105 duration-700 w-[23.5rem]" />
        <p className="absolute m-auto left-0 right-0 top-0 bottom-0 text-center h-5 opacity-0 group-hover:opacity-100 duration-700">
          <Link to={`/race/${race.id}`}>
            <button className="bg-green-500 text-white text-xl font-bold rounded-full px-6 py-1.5 hover:bg-green-700 duration-300">
              See Details
              <BiRightArrow size={35} color="#fff" className="inline pb-1 pl-3" />
            </button>
          </Link>
        </p>
      </div>
      <div className="flex space-x-2 mt-2 mb-2 text-sm group-hover:scale-105 group-hover:mt-4 duration-700">
        <p className="uppercase font-semibold">{race.startDate.bih}</p>
        <p>·</p>
        <p className="uppercase font-semibold">{race.location}</p>
      </div>
      <h2 className="xs:text-lg sm:text-2xl font-bold uppercase group-hover:scale-105 duration-700">{race.title}</h2>
      <p className="sm:max-w-[22.5rem] mt-[0.4rem] group-hover:scale-105 duration-700 text-[14px]">
        {race.description?.par1?.bih ? race.description.par1.bih : race.description}
      </p>
    </div>
  );
};

export default RaceCard;
